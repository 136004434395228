import { CSSProperties } from "@stitches/react";
import React, { useState } from "react";
import { styled } from "../stitches.config";

import { bodyLarge } from "./commonStyles/Fonts";
import SubscribeButton from "./SubscribeButton";

interface StarButtonProps {
  active?: boolean;
  text: string;
  onToggle?: () => void;
  forceActive?: boolean;
  style?: CSSProperties;
}

const StarButton: React.FC<StarButtonProps> = ({
  active = false,
  text,
  onToggle,
  forceActive,
  style,
  ...props
}) => {
  const [isActive, setActive] = useState(active);
  const toggleClass = () => {
    setActive(!isActive);
    onToggle && onToggle();
  };
  return (
    <StyledStarButton style={style} className={bodyLarge()} onClick={toggleClass}>
      <span>{text}</span>
      <SubscribeButton forceActive={forceActive} subscribed={isActive} />
    </StyledStarButton>
  );
};

const StyledStarButton = styled("div", {
  border: "2px solid $grayscale10",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "10px 12px",
  pointerEvents: "none",
});

export default StarButton;
